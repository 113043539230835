import styled from 'styled-components';

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: var(--indigo-1000);
`;

export const Content = styled.main`
  flex-grow: 1;
  position: relative;
  overflow: hidden;
`;
