import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px 120px;
  margin: 0 auto;
  width: 100%;
  max-width: 920px;

  .LessonWords__Card {
    display: flex;
    justify-content: center;
    padding: 24px 10px;
    transition: background-color 0.2s;
    cursor: pointer;

    &.active {
      pointer-events: none;
      background-color: var(--deep-purple-a50);
    }
  }
`;
