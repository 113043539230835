import styled from 'styled-components';

export const Wrapper = styled.div`
  --padding: 46px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 32px;
  position: relative;
  margin: 0 auto;
  padding: var(--padding);
  width: 100%;
  max-width: calc(1080px + var(--padding) * 2);
  height: 100%;
`;

export const Instructions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

export const Content = styled.div`
  flex-grow: 1;
  height: 0;
  overflow-y: auto;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
