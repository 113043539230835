import styled from 'styled-components';

export const AnswerOptionRoot = styled.label`
  --bg-color: var(--deep-purple-a50);
  --bg-checked-color: var(--deep-purple-a200);
  --bg-hover-color: var(--deep-purple-a100);
  --text-color: var(--navy-900);

  padding: 16px 32px;
  min-width: 220px; // TODO: consider about dynamic width
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-color);
  border-radius: 14px;
  text-align: center;
  cursor: pointer;

  & > svg {
    opacity: 0;
    width: 0px;
    margin-right: 0;
  }

  & > :is(svg, span) {
    color: var(--text-color);
    user-select: none;
    transition: opacity 0.3s ease, width 0.3s ease, margin-right 0.3s ease;
    flex-shrink: 0;
  }

  input:not(:disabled):not(:checked) + & {
    &:hover {
      --bg-color: var(--bg-hover-color);
    }
  }

  input:checked + & {
    --bg-color: var(--bg-checked-color);
    & > svg {
      opacity: 1;
      width: 32px;
      margin-right: 8px;
    }
  }
`;
