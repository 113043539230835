import styled from 'styled-components';

export const InputBase = styled.div`
  /* TODO replace on UI-kit color pallete */
  --text-color: #2a2564;
  --placeholder-text-color: #a09fa9;
  --bg-color: #f8f8f8;
  --outline-color: currentColor;
  --invalid-color: crimson;
  --font-size: 36px;
  --offset-x: 36px;

  display: grid;
  gap: 8px;
`;

export const InputRoot = styled.input`
  background-color: var(--bg-color);
  padding: 16px var(--offset-x);
  border-radius: 48px;
  outline: var(--outline-color) none 0px;
  color: var(--text-color);
  font-size: var(--font-size);
  line-height: 1.5;

  ::placeholder {
    color: var(--placeholder-text-color);
  }

  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  :focus-visible,
  &[aria-invalid='true'] {
    outline-style: solid;
    outline-width: 4px;
  }

  :focus-visible {
    --outline-color: var(--purple-a100);
  }

  &[aria-invalid='true'] {
    --outline-color: var(--invalid-color);
  }
`;

export const InputAlert = styled.span`
  margin-left: var(--offset-x);
  color: var(--invalid-color);
  font-size: 18px;
`;
