import Card from 'components/ui/Card';
import Text from 'components/ui/Text';

const SoundCard = ({ sound }: { sound: string }) => (
  <Card
    style={{
      display: 'flex',
      padding: '2px 10px',
      borderRadius: 8,
    }}
  >
    <Text bold style={{ margin: 'auto', lineHeight: 1 }}>
      {sound}
    </Text>
  </Card>
);

export default SoundCard;
