import useUserLSState, { initialState } from 'hooks/useUserLSState';

import ContentCentered from 'components/ui/ContentCentered';
import MenuCard from 'components/ui/MenuCard';

import { URLS } from 'components/App';

import * as Styled from './styles';

const Menu = () => {
  const { userState } = useUserLSState();

  return (
    <ContentCentered gradientCirclePosition="center">
      <Styled.MenuRoot>
        <MenuCard type="story" status={userState?.menu?.story ?? initialState.menu.story} to={URLS.story} />
        <MenuCard type="lesson" status={userState?.menu?.lesson ?? initialState.menu.lesson} to={URLS.lessonSound} />
      </Styled.MenuRoot>
    </ContentCentered>
  );
};

export default Menu;
