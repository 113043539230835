import { useState } from 'react';
import { useLocalStorage } from 'react-use';

import createProvider from 'lib/createProvider';

const [useMuteAudio, MuteAudioProvider] = createProvider(() => {
  const [storedAudioMuted, setStoredAudioMuted] = useLocalStorage('luca:audioMuted', false);
  const [audioMuted, setAudioMuted] = useState(!!storedAudioMuted);

  const toggle = () => {
    setStoredAudioMuted(!audioMuted);
    setAudioMuted((prev) => !prev);
  };

  return {
    audioMuted,
    toggleAudioMuted: toggle,
  };
});

export default useMuteAudio;
export { MuteAudioProvider };
