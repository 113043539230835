import { useMutation } from '@tanstack/react-query';
import camelcaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

import { apiClientSoundOutWord } from 'lib/apiClient';

const getSound = async (wordString: string) => {
  const params = snakeCaseKeys({ wordString });
  const res = await apiClientSoundOutWord.get<Blob>(`/sound_out_word`, {
    params,
  });

  return {
    audio: URL.createObjectURL(res.data),
    highlights: camelcaseKeys(JSON.parse(res.headers['x-highlights']), { deep: true }),
  };
};

const useSoundOutWord = () => useMutation(getSound);

export default useSoundOutWord;
