import { createContext, ReactNode, useContext } from 'react';

const createProvider = <J extends Array<any>, K extends unknown, T extends (...args: J | any[]) => K>(
  useContextValue: T
) => {
  const Context = createContext<unknown>(null) as React.Context<K>;

  const Provider = ({ args, children }: { args?: J; children?: ReactNode | ((value: ReturnType<T>) => ReactNode) }) => {
    const resolvedArgs = args ? args : [];
    const value = useContextValue(...resolvedArgs);
    return (
      <Context.Provider value={value}>
        {typeof children === 'function' ? children?.(value as ReturnType<T>) : children}
      </Context.Provider>
    );
  };

  const ContextHook = (() => useContext(Context)) as T;

  return [ContextHook, Provider] as [typeof ContextHook, typeof Provider];
};

export default createProvider;
