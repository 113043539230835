import styled from 'styled-components';

import type { TextProps } from './Text';

export const Text = styled.div<Omit<TextProps, 'children'>>`
  color: ${({ colorType }) => {
    switch (colorType) {
      case 'dark':
        return 'var(--navy-900)';
      case 'light':
        return 'var(--grey-100)';
      default:
        return 'var(--navy-900)';
    }
  }};
  font-size: ${({ variant }) => {
    switch (variant) {
      case 'xsm':
        return '20px';
      case 'sm':
        return '24px';
      case 'md':
        return '36px';
      case 'lg':
        return '48px';
      default:
        return '36px';
    }
  }};
  line-height: ${({ variant }) => {
    switch (variant) {
      case 'xsm':
        return 1;
      case 'sm':
        return 1.5;
      case 'md':
        return 1.5;
      case 'lg':
        return 1;
      default:
        return 1.5;
    }
  }};
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  text-align: ${({ align }) => {
    switch (align) {
      case 'left':
        return 'left';
      case 'center':
        return 'center';
      case 'right':
        return 'right';
      default:
        return 'left';
    }
  }};
`;
