import styled from 'styled-components';

import AudioBoxComponent from 'components/AudioBox';

export const AudioTextCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  /* TODO: figure out how to make it user-friendly */
  width: 100%;
  height: 350px;
  border-radius: 28px;
  overflow: hidden;
`;

export const AudioBox = styled(AudioBoxComponent)`
  flex-grow: 1;
  height: 0;
  padding: 44px;
  overflow-y: auto;
`;
