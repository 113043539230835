import styled from 'styled-components';

export const Story = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0 160px;
  max-width: 1280px;
  width: 100%;

  .Story {
    &__AudioTextCard {
      margin: 32px 0 86px;
    }

    &__ProgressBar {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }
`;

export const Controls = styled.div`
  display: grid;
  grid-template-columns: minmax(min-content, 72px);
  justify-items: center;
  row-gap: 36px;
  position: absolute;
  top: 24%;
  left: 0;
`;

export const Navigation = styled.div`
  display: grid;
  grid-template-columns: minmax(min-content, 150px) 1fr minmax(min-content, 150px);
  align-items: start;
  justify-items: center;
  width: 100%;

  .Navigation__Action {
    &--hidden {
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s linear, visibility 0s linear 0.3s;
    }
    &--visible {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s linear, visibility 0s linear;
    }
  }
`;
