import { TransitionStatus } from 'react-transition-group';
import styled from 'styled-components';

export const ContentPlug = styled.div<{ duration: number; state: TransitionStatus }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding-bottom: 10%;
  width: 100%;
  height: 100%;
  background-color: var(--indigo-1000);

  transition: opacity ${({ duration }) => `${duration}ms`} ease-in-out;
  opacity: ${({ state }) => (['entering', 'entered'].includes(state) ? 1 : 0)};
`;
