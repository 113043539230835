import styled from 'styled-components';

import type { GradientCircleProps } from './GradientCircle';

export const GradientCircle = styled.div<GradientCircleProps>`
  position: absolute;
  top: 100%;
  left: ${({ position }) => (position === 'right' ? '100%' : '50%')};
  width: 1300px;
  height: 1300px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--purple-a200);
  filter: blur(600px);
`;
