import styled from 'styled-components';

import AudioBoxComponent from 'components/AudioBox';

export const AudioBox = styled(AudioBoxComponent)`
  margin: 0 auto;
  padding: 110px;
  width: max-content;
  transition: background-color 0.2s;

  &.active {
    background-color: var(--deep-purple-a50);
  }
`;
