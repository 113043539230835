import { useLocalStorage, useMount, useStateList, useUpdateEffect } from 'react-use';

const TEXT_SIZES = [
  { id: 2, text: 'Medium', value: 36 },
  { id: 3, text: 'Big', value: 48 },
  { id: 1, text: 'Small', value: 28 },
];

const SPEED_VALUES = [
  { id: 2, text: 'Normal', value: 1 },
  { id: 3, text: 'Fast', value: 1.25 },
  { id: 1, text: 'Slow', value: 0.75 },
];

const useAudioTextControls = () => {
  const [controlsLS, setControlsLS] = useLocalStorage('luca:controls', { fontSize: 0, speed: 0 }); // indexes

  const textSizeStateManager = useStateList(TEXT_SIZES);
  const audioSpeedStateManager = useStateList(SPEED_VALUES);

  useMount(() => {
    textSizeStateManager.setStateAt(controlsLS?.fontSize ?? 0);
    audioSpeedStateManager.setStateAt(controlsLS?.speed ?? 0);
  });

  // skip effect running on mount
  useUpdateEffect(() => {
    setControlsLS({ fontSize: textSizeStateManager.currentIndex, speed: audioSpeedStateManager.currentIndex });
  }, [textSizeStateManager.currentIndex, setControlsLS, audioSpeedStateManager.currentIndex]);

  return { textSizeStateManager, audioSpeedStateManager };
};

export default useAudioTextControls;
