import styled, { css } from 'styled-components';

import type { PillProps } from './Pill';

export const Pill = styled.div<Pick<PillProps, 'icon'>>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 16px 64px;
  border-bottom-right-radius: 48px;
  background-color: var(--purple-a400);
  /* TODO: replace to var */
  filter: drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.18));

  ${({ icon }) =>
    icon &&
    css`
      gap: 24px;
    `}
`;
