import styled from 'styled-components';

export const ProgressBar = styled.div`
  position: relative;
  overflow: hidden;
  width: 340px;
  height: 12px;
  border-radius: 35px;
  background-color: var(--grey-200);
`;

export const ProgressBarBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50px;
  border-radius: 35px;
  background-image: repeating-linear-gradient(
      296deg,
      var(--white-transparent-10) 0,
      var(--white-transparent-10) 4px,
      transparent 4px,
      transparent 6px
    ),
    var(--gradient-progress-bar);
  background-repeat: no-repeat, repeat-x;
  transition: width 0.2s ease;
`;
