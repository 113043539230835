import MuteAudio from 'components/MuteAudio';
import User from 'components/User';
import Logo from 'components/Logo';

import * as Styled from './styles';

const Header = () => (
  <Styled.Header>
    <Logo />
    <MuteAudio />
    <User />
  </Styled.Header>
);

export default Header;
