import styled, { css, keyframes } from 'styled-components';

import { RecordingControlProps } from './RecordingControl';

const pulseBorder1 = keyframes`
  from {
    margin: 0px;
    opacity: 1;
    border-width: 6px;
    }

  to {
    margin: var(--offset);
    opacity: 0;
    border-width: 3px;
    border-color: rgba(var(--deep-purple-a300-rgb), 0.6);
  }
`;

const pulseBorder2 = keyframes`
  from {
    margin: 0px;
    opacity: 1;
    border-width: 6px;
  }

  to {
    margin: var(--offset);
    opacity: 0;
    border-width: 2px;
    border-color: rgba(var(--deep-purple-a300-rgb), 0.4);
  }
`;

export const RecordingControlContainer = styled.div`
  display: grid;
  grid-template-columns: max-content;
  justify-items: center;
  gap: 24px;

  .RecordingControl__Text {
    height: 36px;
  }
`;

export const RecordingControlRoot = styled.button<Pick<RecordingControlProps, 'status'>>`
  --animation-duration: 1500ms;
  /* Figure out how to pulse depends on volume level of speaking person/playing audio. Probably we have to rely on transform:scale instead of margin */
  --offset: -30px;
  position: relative;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 0;

  ${({ status }) =>
    status === 'recording' &&
    css`
      &::after,
      &::before {
        position: absolute;
        content: '';
        inset: 2px;
        background-color: transparent;
        border-radius: 50%;
        border: 6px solid var(--deep-purple-a300);
        margin: 0px;
        transform-origin: center;
        z-index: -1;
      }
      &::after {
        opacity: 1;
        animation: ${pulseBorder1} var(--animation-duration) linear infinite;
      }

      &::before {
        opacity: 0.8;
        animation: ${pulseBorder2} var(--animation-duration) linear calc(var(--animation-duration) / 2) infinite;
      }
    `}

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &::after,
    &::before {
      content: none; // disabled pulse effect
    }
  }
`;
