import { useMemo } from 'react';

import { StatusMessages } from 'hooks/useMediaRecorder';

import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import {
  Pause,
  Play,
  ProgressBar,
  ProgressType,
  Recording,
  RingCircle,
  Stop,
  createProgressBarCircle,
} from './Controls';
import * as Styled from './styles';

export type PlaybackStatus = 'playback_playing' | 'playback_paused' | 'playback_stoped';

export type RecordingControlProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  status?: StatusMessages | PlaybackStatus;
  svgProps?: React.SVGAttributes<SVGElement>;
  progress?: ProgressType;
  text?: string;
};

const controlToRenderByStatus: Record<StatusMessages | PlaybackStatus, React.ReactElement> = {
  acquiring_media: <Recording />,
  idle: <Recording />,
  paused: <Recording />,
  stopping: <Play />,
  stopped: <Play />,
  playback_paused: <Play />,
  playback_stoped: <Play />,
  playback_playing: <Pause />,
  recording: <Stop />,
};

export const RecordingControl = ({
  status = 'idle',
  progress = -1,
  text,
  className,
  svgProps,
  ...buttonProps
}: RecordingControlProps) => {
  const { progressCircleAttrs, ringCircleAttrs } = useMemo(() => createProgressBarCircle({ progress }), [progress]);

  return (
    <Styled.RecordingControlContainer className={className}>
      <Styled.RecordingControlRoot type="button" status={status} {...buttonProps}>
        <SvgIcon
          width={110}
          height={110}
          inheritViewBox={false}
          viewBox="0 0 110 110"
          color="var(--purple-a400)"
          {...svgProps}
        >
          <RingCircle {...ringCircleAttrs} />
          {status === 'playback_playing' && progress >= 0 && <ProgressBar {...progressCircleAttrs} />}
          {controlToRenderByStatus[status]}
        </SvgIcon>
      </Styled.RecordingControlRoot>
      <Text as="span" variant="sm" colorType="light" className="RecordingControl__Text">
        {text}
      </Text>
    </Styled.RecordingControlContainer>
  );
};

export default RecordingControl;
