import styled from 'styled-components';

import CardComponent from 'components/ui/Card';

export const Card = styled(CardComponent)`
  padding: 60px;

  .LessonWordsSelect__Word {
    position: relative;

    &:before {
      --dif: 10%;

      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: calc(var(--dif) / -2);
      width: calc(100% + var(--dif));
      height: 100%;
      background-color: var(--indigo-100);
      border-radius: 26px;
      opacity: 0;
      transition: opacity 0.2s;
    }

    &.selected {
      &:before {
        opacity: 0.4;
      }
    }
  }
`;
