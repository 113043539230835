import styled from 'styled-components';

export const AgeOptionRoot = styled.label`
  --bg-color: var(--deep-purple-600);
  --bg-checked-color: var(--deep-purple-a400);
  --bg-hover-color: var(--deep-purple-a300);
  --text-color: var(--gray50);

  padding: 24px 16px;
  display: block;
  background-color: var(--bg-color);
  border-radius: 59px;
  color: var(--text-color);
  text-align: center;
  cursor: pointer;

  & > span {
    line-height: 1;
  }

  input:not(:disabled):not(:checked) + & {
    &:hover {
      --bg-color: var(--bg-hover-color);
    }
  }

  input:checked + & {
    --bg-color: var(--bg-checked-color);
  }
`;
