import { PolymorphicComponentProps } from 'types';

import * as Styled from './styles';

type TextVariant = 'xsm' | 'sm' | 'md' | 'lg';
type TextColorType = 'dark' | 'light';

export type TextProps = {
  variant?: TextVariant;
  colorType?: TextColorType;
  bold?: boolean;
  children: string | React.ReactNode;
  as?: 'div' | 'p' | 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  className?: string;
  align?: 'left' | 'center' | 'right';
};

const Text = <E extends React.ElementType = 'div'>({
  variant = 'md',
  colorType = 'dark',
  bold = false,
  children,
  as = 'div',
  className,
  align = 'left',
  ...restProps
}: PolymorphicComponentProps<E, TextProps>) => (
  <Styled.Text
    as={as}
    variant={variant}
    colorType={colorType}
    bold={bold}
    align={align}
    className={className}
    {...restProps}
  >
    {children}
  </Styled.Text>
);

export default Text;
