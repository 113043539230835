import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import * as Styled from './styles';

export type PillProps = {
  className?: string;
  children: string | React.ReactNode;
  icon?: 'svg' | React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

const Pill = ({ className, icon, children }: PillProps) => (
  <Styled.Pill className={className} icon={icon}>
    <Text variant="sm" colorType="light">
      {children}
    </Text>
    {icon && <SvgIcon component={icon} />}
  </Styled.Pill>
);

export default Pill;
