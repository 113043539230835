import styled from 'styled-components';

import Dialog from 'components/ui/Dialog';
import FormGroup from 'components/ui/FormGroup';

export const QuestionsDialog = styled(Dialog)`
  width: 80%;
`;

export const FormGroupSingle = styled(FormGroup)`
  width: fit-content;
`;

export const FormGroupMultiple = styled(FormGroup)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 12px 36px;
`;
