import styled, { css } from 'styled-components';

import type { DialogProps } from './Dialog';

export const Dialog = styled.div<Pick<DialogProps, 'position'>>`
  position: relative;

  ${({ position }) =>
    position === 'left'
      ? css`
          margin-right: auto;
        `
      : css`
          margin-left: auto;
        `}

  &:before,
  &:after {
    display: block;
    content: '';
    position: absolute;
    bottom: -10px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 8px 32px 8px 0;
    border-color: transparent var(--grey-100) transparent transparent;
    transform: rotateZ(-35deg);
    ${({ position }) => {
      switch (position) {
        case 'right':
          return css`
            transform: rotateZ(35deg) rotateY(180deg);
            right: -14px;
          `;
        case 'left':
        default:
          return css`
            left: -14px;
          `;
      }
    }}
  }

  &:before {
    bottom: -14px;
    border-color: transparent var(--black-transparent-10) transparent transparent;
  }

  .Dialog__Card {
    padding: 32px;
  }
`;
