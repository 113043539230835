import Header from 'components/Header';

import * as Styled from './styles';

const Layout = ({ children }: { children?: React.ReactNode }) => (
  <Styled.Layout>
    <Header />
    <Styled.Content>{children}</Styled.Content>
  </Styled.Layout>
);

export default Layout;
