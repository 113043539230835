import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 40px;
  position: relative;
  padding: 0 70px 48px 290px;
  width: 100%;
  height: 100%;

  .ContentLuca__Image {
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

export const Content = styled.div`
  flex-grow: 1;
  height: 0;
  overflow-y: auto;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 80px;
  gap: 16px;
`;
