import { Link } from 'react-router-dom';
import type { To } from 'react-router-dom';

import { MenuItemStatuses } from 'types';

import Card from 'components/ui/Card';
import Image from 'components/ui/Image';
import Pill from 'components/ui/Pill';
import Text from 'components/ui/Text';

import { LOCKER_ICON } from 'assets/icons';
import LESSON from 'assets/images/lesson.png';
import STORY from 'assets/images/story.png';

import * as Styled from './styles';

export type MenuCardProps = {
  type?: 'lesson' | 'story';
  status?: MenuItemStatuses;
  to: To;
};

export const MenuCard = ({ type = 'lesson', status = 'ready', to }: MenuCardProps) => (
  <Styled.MenuCard status={status} as={Link} to={status === 'ready' ? to : '#'}>
    <Card type="dark" className="MenuCard__Card">
      {status === 'locked' && (
        <Pill className="MenuCard__Pill" icon={LOCKER_ICON}>
          {type === 'lesson' ? 'Read a story to unlock' : 'Complete a lesson to unlock'}
        </Pill>
      )}
      {status === 'completed' && <Pill className="MenuCard__Pill">You’ve completed the {type}</Pill>}
      <Image src={type === 'lesson' ? LESSON : STORY} />
    </Card>
    <Text variant="lg" colorType="light" align="center" className="MenuCard__Text">
      {type}
    </Text>
  </Styled.MenuCard>
);

export default MenuCard;
