import Text from 'components/ui/Text';
import * as Styled from './styles';

export const AgeOption = ({ children, ...props }: React.LabelHTMLAttributes<HTMLLabelElement>) => {
  return (
    <Styled.AgeOptionRoot {...props}>
      <Text as="span" bold colorType="light">
        {children}
      </Text>
    </Styled.AgeOptionRoot>
  );
};

export default AgeOption;
