import styled, { css } from 'styled-components';

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 1em;
  background-color: var(--indigo-1000);
  border-radius: 50%;
  font-size: 77px;

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: var(--indigo-600);
      }
    `}
`;
