import { memo } from 'react';

import * as Styled from './styles';

export type GradientCirclePosition = 'center' | 'right';
export type GradientCircleProps = {
  position?: GradientCirclePosition;
};

const GradientCircle = ({ position = 'right' }: GradientCircleProps) => <Styled.GradientCircle position={position} />;

export default memo(GradientCircle);
