import styled from 'styled-components';

import type { CardProps } from './Card';

export const Card = styled.div<Pick<CardProps, 'type'>>`
  padding: 10px;
  border-radius: 28px;
  box-shadow: var(--box-shadow);
  background-color: ${({ type }) => {
    switch (type) {
      case 'dark':
        return 'var(--navy-800)';
      case 'light':
        return 'var(--grey-100)';
      default:
        return 'var(--grey-100)';
    }
  }};
`;
