import * as Styled from './styles';

type CardType = 'dark' | 'light';

export type CardProps = React.HTMLAttributes<HTMLDivElement> & {
  type?: CardType;
  className?: string;
  children?: string | React.ReactNode;
};

export const Card = ({ type = 'light', className, children, ...restProps }: CardProps) => (
  <Styled.Card type={type} className={className} {...restProps}>
    {children}
  </Styled.Card>
);

export default Card;
