type RenderSwitchCase = {
  condition: unknown;
  render: React.ReactNode | (() => React.ReactNode);
};

export default function renderSwitch<T extends string | number | symbol>(
  map: Record<T, RenderSwitchCase>
): React.ReactNode {
  for (const key in map) {
    if (map.hasOwnProperty(key)) {
      const { condition, render } = map[key];
      const state: React.ReactNode = typeof render === 'function' ? render() : render;
      if (!!condition && state) {
        return state;
      }
    }
  }
  return null;
}
