import Button from 'components/ui/Button';
import type { CardProps } from 'components/ui/Card';
import Text from 'components/ui/Text';

import * as Styled from './styles';

type ErrorCardProps = CardProps & {
  message: string;
  onRetry: () => void;
};

const ErrorCard = ({ message, onRetry, ...restProps }: ErrorCardProps) => (
  <Styled.ErrorCard {...restProps}>
    <Text align="center">{message}</Text>
    <Button onClick={onRetry}>Retry</Button>
  </Styled.ErrorCard>
);

export default ErrorCard;
