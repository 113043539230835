/**
 * Source: https://dev.to/hyposlasher/no-more-react-context-hell-4pm3
 */
import { cloneElement } from 'react';

type Props = {
  providers: Array<React.ReactElement>;
  children: React.ReactNode;
};

const Providers = ({ providers, children }: Props) => {
  const renderProvider = (
    providers: Array<React.ReactElement>,
    children: React.ReactNode
  ): JSX.Element | React.ReactNode => {
    const [provider, ...restProviders] = providers;

    if (provider) {
      return cloneElement(provider, undefined, renderProvider(restProviders, children));
    }

    return children;
  };

  return <>{renderProvider(providers, children)}</>;
};

export default Providers;
