import clsx from 'clsx';
import { MouseEvent, ReactNode, RefObject, useRef, useState } from 'react';

import { isClickFromRelatedElement } from 'lib/utils';

import Audio, { AudioProps } from 'components/ui/Audio';
import type { AudioRef } from 'components/ui/Audio';

import * as Styled from './styles';

export type AudioBoxProps = {
  audio?: string;
  audioSpeed?: number;
  playingAudioRef?: RefObject<AudioRef>;
  className?: string;
  activeClassName?: string;
  onAudioStateUpdate?: AudioProps['onStateUpdate'];
  children: string | ReactNode;
};

const AudioBox = ({
  audio,
  audioSpeed = 1,
  playingAudioRef,
  className,
  activeClassName = '',
  onAudioStateUpdate,
  children,
}: AudioBoxProps) => {
  const audioRef = useRef<AudioRef>(null);
  const [isAudioPlaying, setIsAudionPlaying] = useState(false);

  const playAudio = (event: MouseEvent<HTMLDivElement>) => {
    // prevent click on tooltip related element
    if (isClickFromRelatedElement(event.target as HTMLElement, 'tooltip')) return;

    // stop another audio (mostly description)
    if (playingAudioRef) playingAudioRef.current?.stop();
    audioRef.current?.play();
    setIsAudionPlaying(true);
  };

  const handleAudioStateUpdate: AudioProps['onStateUpdate'] = (state, audioEl) => {
    onAudioStateUpdate?.(state, audioEl);

    state.playing !== isAudioPlaying && setIsAudionPlaying(state.playing);
  };

  return (
    <Styled.Card
      onClick={playAudio}
      className={clsx(className, {
        active: isAudioPlaying,
        [activeClassName]: isAudioPlaying,
      })}
    >
      <Audio
        src={audio}
        speed={audioSpeed}
        ignoreAppMute
        autoPlay={false}
        ref={audioRef}
        onStateUpdate={handleAudioStateUpdate}
      />
      {children}
    </Styled.Card>
  );
};

export default AudioBox;
