import styled from 'styled-components';

export const Sentence = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
  gap: 0 8px;
  user-select: none;
  font-size: 36px;
`;
