import { useRef, useState } from 'react';

import { AudioProps, AudioRef } from 'components/ui/Audio';
import { PlaybackStatus } from 'components/ui/RecordingControl';

const useHandlePlayback = (status?: PlaybackStatus) => {
  const audioRef = useRef<AudioRef | null>(null);
  const [playbackStatus, setPlaybackStatus] = useState<PlaybackStatus | undefined>(status);

  const resetStatus = () => setPlaybackStatus(undefined);

  const audioProps: AudioProps & { ref?: typeof audioRef } = {
    ref: audioRef,
    autoPlay: false,
    ignoreAppMute: true,
    onStateUpdate: (state) => {
      if (state.playing) setPlaybackStatus('playback_playing');
      if (state.paused) setPlaybackStatus('playback_paused');
    },
  };

  return {
    playbackStatus,
    audioProps,
    resetStatus,
    actions: audioProps.ref?.current,
  };
};

export default useHandlePlayback;
