import { ReactNode, useState } from 'react';

import { getWordsFromText, guessPlaybackTime, parseHighlights } from 'lib/utils';

import { HighlightsEntity } from 'types';

import { AudioProps } from 'components/ui/Audio';
import Sentence from 'components/ui/Sentence';
import Word from 'components/ui/Word';

import type { AudioBoxProps } from 'components/AudioBox';

import * as Styled from './styles';

type AudioTextCardProps = {
  fontSize?: number;
  text: string;
  details?: ReactNode;
  highlights: HighlightsEntity[];
  className?: string;
} & Omit<AudioBoxProps, 'children'>;

// Now is used only on Story screen
const AudioTextCard = ({
  fontSize = 36,
  text,
  details,
  highlights,
  className,
  ...audioBoxProps
}: AudioTextCardProps) => {
  const [highlightedIndices, setHighlightedIndices] = useState<Array<number>>([]);
  const [currentHighlightedIndices, setCurrentHighlightedIndices] = useState<Array<number>>([]);

  const handleAudioStateUpdate: AudioProps['onStateUpdate'] = (state, audioEl) => {
    const currentTime = guessPlaybackTime(state, audioEl);

    if (state.buffered.length && state.duration === currentTime) {
      setCurrentHighlightedIndices([]);
      setHighlightedIndices([]);
      return;
    }

    // additional state clean to handle ERR_REQUEST_RANGE_NOT_SATISFIABLE
    if ((highlightedIndices.length || currentHighlightedIndices.length) && state.paused) {
      setHighlightedIndices([]);
      return;
    }

    const currentHighlights = parseHighlights(highlights, currentTime, highlightedIndices);
    setCurrentHighlightedIndices(currentHighlights);
    setHighlightedIndices((prev) => prev.concat(currentHighlights));
  };

  const words = getWordsFromText(text);

  return (
    <Styled.AudioTextCard className={className}>
      <Styled.AudioBox
        key={text}
        {...audioBoxProps}
        onAudioStateUpdate={highlights ? handleAudioStateUpdate : undefined}
      >
        <Sentence style={{ fontSize }}>
          {words.map((word, idx) => {
            const isHighlighted = currentHighlightedIndices.includes(idx);

            return (
              <Word key={`${word}_${idx}`} withTooltip withSound isHighlighted={isHighlighted}>
                {word}
              </Word>
            );
          })}
        </Sentence>
      </Styled.AudioBox>
      {details}
    </Styled.AudioTextCard>
  );
};

export default AudioTextCard;
