import SvgIcon from '../SvgIcon';
import Text from '../Text';

import type { ButtonProps } from './Button';
import * as Styled from './styles';

export type IconButtonProps = ButtonProps & {
  variant?: 'control' | 'action';
  icon?: 'svg' | React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  /**
   * href(id) of icon from svg sprite.
   */
  xlinkHref?: string;
  /**
   * Attributes that svg accepts
   */
  svgProps?: React.SVGAttributes<SVGAElement>;
};

/**
 * Primary UI component for user interaction
 */
export const IconButton = ({
  type = 'button',
  size = 'auto',
  variant = 'action',
  icon,
  children,
  xlinkHref = '',
  svgProps,
  className,
  ...buttonProps
}: IconButtonProps) => {
  const IconButtonComponent = (
    <Styled.IconButtonRoot type={type} size={size} variant={variant} {...buttonProps}>
      {!!icon && <SvgIcon component={icon} {...svgProps} />}
      {!!xlinkHref && (
        <SvgIcon {...svgProps}>
          <use xlinkHref={xlinkHref} />
        </SvgIcon>
      )}
    </Styled.IconButtonRoot>
  );

  if (!children) return IconButtonComponent;

  return (
    <Styled.ButtonContainer className={className}>
      {IconButtonComponent}
      <Text as="span" variant="xsm" colorType="light">
        {children}
      </Text>
    </Styled.ButtonContainer>
  );
};

export default IconButton;
