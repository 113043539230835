import SvgIcon from '../SvgIcon';
import Text from '../Text';

import * as Styled from './styles';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  /**
   * Size of the button (width)
   */
  size?: 'medium' | 'auto' | 'fluid';
  iconLeft?: 'svg' | React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  iconRight?: 'svg' | React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  type = 'button',
  size = 'auto',
  iconLeft,
  iconRight,
  children,
  ...buttonProps
}: ButtonProps) => {
  return (
    <Styled.ButtonRoot type={type} size={size} {...buttonProps}>
      {typeof children === 'string' ? (
        <>
          {iconLeft && <SvgIcon color="var(--grey-100)" component={iconLeft} />}
          <Text className="Button__Text" as="span" colorType="light" variant="sm" bold>
            {children}
          </Text>
          {iconRight && <SvgIcon color="var(--grey-100)" component={iconRight} />}
        </>
      ) : (
        children
      )}
    </Styled.ButtonRoot>
  );
};

export default Button;
