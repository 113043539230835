import useMuteAudio from 'hooks/useMuteAudio';

import SvgIcon from 'components/ui/SvgIcon';
import Circle from 'components/ui/Circle';

import { MUTED_ICON, UNMUTED_ICON } from 'assets/icons';

const MuteAudio = () => {
  const { audioMuted, toggleAudioMuted } = useMuteAudio();

  return (
    <Circle as="button" title={`${audioMuted ? 'Unmute' : 'Mute'} audio`} onClick={toggleAudioMuted}>
      <SvgIcon component={audioMuted ? MUTED_ICON : UNMUTED_ICON} />
    </Circle>
  );
};

export default MuteAudio;
