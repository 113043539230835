import { HTMLMediaState } from 'react-use/lib/factory/createHTMLMediaHook';
import snakecaseKeys from 'snakecase-keys';

import { HighlightsEntity } from 'types';

export const getAudioSrcFromBase64 = (base64String: string) => `data:audio/wav;base64,${base64String}`;

export const getWordsFromText = (text: string) =>
  text.split(' ').reduce((arr, word) => (word.length ? arr.concat(word) : arr), [] as string[]);

export const isClickFromRelatedElement = (element: HTMLElement, className: string) => {
  let target = element;

  while (target !== document.body) {
    if (target.className?.includes?.(className) || !target.parentElement) return true;
    target = target.parentElement;
  }

  return false;
};

export const toSnakeCase = (str: string, { lowercase = false } = {}): string => {
  let key = str.trim();

  if (lowercase) {
    key = str.toLowerCase();
  }

  const obj = snakecaseKeys({ [key]: undefined });

  return Object.keys(obj ?? {})?.[0] ?? '';
};

// At some point "state.time" returns greater than duration. This especially happens in Safari
export const guessPlaybackTime = (state: HTMLMediaState, audioEl: HTMLAudioElement) => {
  let currentTime = state.time;

  if (state.time > state.duration) {
    currentTime = audioEl.currentTime;
  }

  if (currentTime > state.duration) {
    currentTime = state.duration;
  }

  return currentTime;
};

export const parseHighlights = (
  highlights: HighlightsEntity[],
  currentTime: number,
  highlightedIndices: number[]
): number[] =>
  highlights.reduce<number[]>(
    (acc, { start, end, indices }) =>
      ((start <= currentTime && end >= currentTime) || end < currentTime) && !highlightedIndices.includes(indices[0])
        ? acc.concat(indices)
        : acc,
    []
  );
