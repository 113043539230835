import { PolymorphicComponentProps } from 'types';

import * as Styled from './styles';

type CircleProps = {
  diameter?: number;
  backgroundColor?: string;
  children?: React.ReactNode;
};

const Circle = <E extends React.ElementType = 'div'>({
  diameter,
  backgroundColor,
  children,
  as = 'div',
  ...restProps
}: PolymorphicComponentProps<E, CircleProps>) => (
  <Styled.Circle as={as} style={{ backgroundColor, fontSize: `${diameter}px` }} {...restProps}>
    {children}
  </Styled.Circle>
);

export default Circle;
