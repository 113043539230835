import { memo } from 'react';

import * as Styled from './styles';

type ImageProps = {
  src: string;
  className?: string;
};

const Image = ({ src, className, ...restProps }: ImageProps) => (
  <Styled.Image src={src} className={className} {...restProps} />
);

export default memo(Image);
