import { ReactNode, RefObject, useRef } from 'react';

import { guessPlaybackTime } from 'lib/utils';

import Audio, { AudioProps } from 'components/ui/Audio';
import type { AudioRef } from 'components/ui/Audio';
import Text from 'components/ui/Text';

import * as Styled from './styles';

type InstructionProps = {
  audio: string;
  audioRef?: RefObject<AudioRef>;
  text: string;
  additionalComponent?: ReactNode;
  additionalAudio?: string;
};

const Instruction = ({ audio, audioRef, text, additionalComponent, additionalAudio }: InstructionProps) => {
  const additionalAudioRef = useRef<AudioRef>(null);

  const handleAudioStateUpdate: AudioProps['onStateUpdate'] = (state, audioEl) => {
    // play additional audio right after instruction audio finished
    if (!additionalAudio || !state.buffered.length || state.duration !== guessPlaybackTime(state, audioEl)) return;

    additionalAudioRef.current?.play();
  };

  return (
    <Styled.Instruction>
      <Audio localSrc={audio} ref={audioRef} onStateUpdate={handleAudioStateUpdate} />
      <Text colorType="light" align="center" variant="sm">
        {text}
      </Text>
      {additionalComponent}
      {additionalAudio && <Audio src={additionalAudio} ref={additionalAudioRef} autoPlay={false} />}
    </Styled.Instruction>
  );
};

export default Instruction;
