import styled from 'styled-components';

export const ContentCommon = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;
