import { Children, cloneElement } from 'react';

import type { FormControlProps, FormControlType } from './FormControl';

import * as Styled from './styles';

export type FormControlTarget = EventTarget & HTMLInputElement;

type FormGroupProps = Omit<React.FieldsetHTMLAttributes<HTMLFieldSetElement>, 'onChange'> & {
  children?: React.ReactElement<FormControlProps, 'div'> | React.ReactElement<FormControlProps, 'div'>[];
  type: FormControlType;
  className?: string;
  onChange: (target: FormControlTarget) => void;
};

export const FormGroup = ({ children, type, className, disabled, onChange, ...fieldsetProps }: FormGroupProps) => {
  return (
    <Styled.FormGroupRoot
      className={className}
      disabled={disabled}
      onChange={(evt) => onChange(evt.target as FormControlTarget)}
      {...fieldsetProps}
    >
      {!!children && Children.map(children, (child) => cloneElement(child, { type, disabled }))}
    </Styled.FormGroupRoot>
  );
};

export default FormGroup;
