import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Transition } from 'react-transition-group';

import Button from 'components/ui/Button/Button';
import Text from 'components/ui/Text/Text';

import { URLS } from 'components/App';

import * as Styled from './styles';

const DURATION = 300;

const ContentPlug = ({ isVisible, onClick }: { isVisible: boolean; onClick: () => void }) => {
  const location = useLocation();
  const nodeRef = useRef(null);

  return (
    <Transition nodeRef={nodeRef} in={isVisible} timeout={DURATION} unmountOnExit>
      {(state) => (
        <Styled.ContentPlug ref={nodeRef} duration={DURATION} state={state}>
          <Button onClick={onClick}>
            <Text colorType="light">{location.pathname === URLS.enterName ? 'Start' : 'Continue journey'}</Text>
          </Button>
        </Styled.ContentPlug>
      )}
    </Transition>
  );
};

export default ContentPlug;
