import styled from 'styled-components';

export const FormControlRoot = styled.div`
  --focus-ring-color: var(--grey-100);

  display: grid;

  & > :is(input, label) {
    grid-area: 1/1;
    inline-size: 100%;
    block-size: 100%;
  }

  input + label {
    transition: outline-offset 0.3s ease, background-color 0.3s ease;
  }

  input:disabled + label {
    cursor: not-allowed;
  }

  input:focus-visible + label {
    outline: 2px solid var(--focus-ring-color);
    outline-offset: 8px;
  }

  input:focus-visible:active + label {
    outline-offset: 0px;
  }
`;

export const FormGroupRoot = styled.fieldset`
  border: none;
`;
