import { PolymorphicComponentProps } from 'types';

import Card from 'components/ui/Card';

import * as Styled from './styles';

export type DialogProps = {
  position?: 'left' | 'right';
  onAnimationEnd?: () => void;
  className?: string;
  children?: string | React.ReactNode;
};

export const Dialog = <E extends React.ElementType = 'div'>({
  position = 'left',
  as = 'div',
  className,
  children,
  ...restProps
}: PolymorphicComponentProps<E, DialogProps>) => (
  <Styled.Dialog as={as} position={position} className={className} {...restProps}>
    <Card className="Dialog__Card">{children}</Card>
  </Styled.Dialog>
);

export default Dialog;
