import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import { CHECK_ICON } from 'assets/icons';

import * as Styled from './styles';

type AnswerOptionProps = React.LabelHTMLAttributes<HTMLLabelElement> & {
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

export const AnswerOption = ({ icon = CHECK_ICON, children, ...props }: AnswerOptionProps) => {
  return (
    <Styled.AnswerOptionRoot {...props}>
      {!!icon && <SvgIcon component={icon} />}
      <Text as="span" variant="sm" bold>
        {children}
      </Text>
    </Styled.AnswerOptionRoot>
  );
};

export default AnswerOption;
