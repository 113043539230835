import type { IntRange } from 'types';

export type ProgressType = -1 | IntRange<0, 101>;

type ProgressBarCircleConfig = {
  direction?: -1 | 1; // counterclockwise | clockwise
  progress: ProgressType;
  radius?: number;
  startPoint?: IntRange<0, 361>;
};

type RingCircleType = ReturnType<typeof createProgressBarCircle>['ringCircleAttrs'];
type ProgressCircleType = ReturnType<typeof createProgressBarCircle>['progressCircleAttrs'];

export function createProgressBarCircle({
  direction = 1,
  progress = -1,
  radius = 50,
  startPoint = 0,
}: ProgressBarCircleConfig) {
  const circumference = 2 * Math.PI * radius;

  const progressOffset = circumference - (progress / 100) * circumference * direction;

  const ringCircleAttrs = {
    r: radius,
    strokeDasharray: circumference,
    strokeDashoffset: 0,
  };

  const progressCircleAttrs = {
    r: radius,
    strokeDasharray: circumference,
    strokeDashoffset: progressOffset,
    transform: `rotate(${270 + startPoint})`,
    'transform-origin': 'center',
  };

  return { ringCircleAttrs, progressCircleAttrs } as const;
}

export const Recording = () => <circle id="recording" cx={55} cy={55} r={35} fill="currentColor" />;

export const Stop = () => <rect id="stop" x={31} y={31} width={48} height={48} rx={10} fill="currentColor" />;

export const Play = () => (
  <path
    id="play"
    d="M25.4 48.067c-.888.577-1.789.61-2.7.098-.91-.51-1.366-1.298-1.366-2.365V18.2c0-1.067.455-1.856 1.365-2.368.912-.51 1.813-.477 2.702.101l21.733 13.8c.8.534 1.2 1.29 1.2 2.267 0 .978-.4 1.733-1.2 2.267l-21.733 13.8Z"
    fill="currentColor"
    transform="translate(24, 24)"
  />
);

export const Pause = () => (
  <path
    id="pause"
    d="M21.833 51.167c2.934 0 5.334-2.4 5.334-5.334V19.167c0-2.934-2.4-5.334-5.334-5.334-2.933 0-5.333 2.4-5.333 5.334v26.666c0 2.934 2.4 5.334 5.333 5.334Zm16-32v26.666c0 2.934 2.4 5.334 5.334 5.334 2.933 0 5.333-2.4 5.333-5.334V19.167c0-2.934-2.4-5.334-5.333-5.334-2.934 0-5.334 2.4-5.334 5.334Z"
    fill="currentColor"
    transform="translate(24, 24)"
  />
);

export const ProgressBar = (progressCircleProps: ProgressCircleType) => (
  <circle
    id="progress-bar"
    cx={55}
    cy={55}
    stroke="currentColor"
    strokeWidth={6}
    fill="transparent"
    {...progressCircleProps}
  />
);

export const RingCircle = (ringCircleProps: RingCircleType) => (
  <circle
    id="ring"
    cx={55}
    cy={55}
    stroke="var(--purple-a100)"
    strokeWidth={6}
    fill="transparent"
    {...ringCircleProps}
  />
);
