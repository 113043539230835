import SvgIcon from 'components/ui/SvgIcon';
import Circle from 'components/ui/Circle';

import { AVATAR_ICON } from 'assets/icons';

const User = () => (
  <Circle>
    <SvgIcon component={AVATAR_ICON} />
  </Circle>
);

export default User;
