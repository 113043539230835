import styled from 'styled-components';

import { Text as TextComponent } from 'components/ui/Text/styles';

export const Text = styled(TextComponent)`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: inherit;

  cursor: pointer;
  transition: color 0.2s;

  &:hover {
    color: var(--purple-a400);
  }

  &::after {
    content: attr(data-text);
    content: attr(data-text) / '';
    height: 0;
    visibility: hidden;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
    font-weight: 700;

    @media speech {
      display: none;
    }
  }

  &.isSelected,
  &.isHighlighted {
    color: var(--purple-700);
  }
`;
