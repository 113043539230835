import styled from 'styled-components';

export const Header = styled.header`
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  justify-items: end;
  gap: 40px;
  position: relative;
  padding: 16px 72px; // values different from design
  height: 110px;
  background-color: var(--navy-800);
  box-shadow: var(--box-shadow);
`;
