import { memo } from 'react';

import * as Styled from './styles';

export type ProgressBarProps = {
  currentStep: number;
  totalSteps: number;
  className?: string;
};

const ProgressBar = ({ currentStep, totalSteps, className }: ProgressBarProps) =>
  currentStep > totalSteps ? null : (
    <Styled.ProgressBar className={className}>
      <Styled.ProgressBarBackground style={{ width: `${Math.floor((currentStep / totalSteps) * 100)}%` }} />
    </Styled.ProgressBar>
  );

export default memo(ProgressBar);
