import { Link } from 'react-router-dom';

import { URLS } from 'components/App';
import SvgIcon from 'components/ui/SvgIcon';

import { LOGO_ICON } from 'assets/icons';

const Logo = () => {
  return (
    <Link to={URLS.enterName}>
      <SvgIcon component={LOGO_ICON} />
    </Link>
  );
};

export default Logo;
