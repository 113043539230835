import { create } from 'zustand';
import { combine } from 'zustand/middleware';

import { HighlightsEntity } from 'types';

type StoredWord = {
  name: string;
  highlights: HighlightsEntity[];
  audio: string;
};

const initialState: {
  storedWords: StoredWord[];
} = {
  storedWords: [],
};

const useWordsStore = create(
  combine(initialState, (set) => ({
    addWord: (word: StoredWord) => set((state) => ({ storedWords: state.storedWords.concat(word) })),
  }))
);

export default useWordsStore;
