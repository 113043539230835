import GradientCircle from 'components/ui/GradientCircle';
import type { GradientCirclePosition } from 'components/ui/GradientCircle';

import * as Styled from './styles';

export type ContentCommonProps = {
  children: string | React.ReactNode;
  gradientCirclePosition?: GradientCirclePosition;
};

const ContentCommon = ({ gradientCirclePosition = 'right', children }: ContentCommonProps) => (
  <Styled.ContentCommon>
    <GradientCircle position={gradientCirclePosition} />
    <Styled.Content>{children}</Styled.Content>
  </Styled.ContentCommon>
);

export default ContentCommon;
