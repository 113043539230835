import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { createURLfromChunks, getUserMedia } from 'lib/mediaRecorder';
import type { MediaRecorderLifecycleHooks } from 'lib/mediaRecorder';

export enum RecorderErrors {
  AbortError = 'media_aborted',
  NotAllowedError = 'permission_denied',
  NotFoundError = 'no_specified_media_found',
  NotReadableError = 'media_in_use',
  OverconstrainedError = 'invalid_media_constraints',
  TypeError = 'no_constraints',
  NONE = '',
  NO_RECORDER = 'recorder_error',
  NOT_SUPPORTED = 'not_supported',
}

export type StatusMessages = 'idle' | 'acquiring_media' | 'recording' | 'stopping' | 'stopped' | 'paused';

export type MediaRecorderHookProps = {
  stopStreamsOnStop?: boolean;
  mediaRecorderOptions?: MediaRecorderOptions;
  askPermissionOnMount?: boolean;
  onStart?: MediaRecorderLifecycleHooks['onstart'];
  onStop?: (blobUrl: string, blob: Blob, chunks: Blob[]) => void;
  onDataAvailable?: (chunk: Blob) => void;
};

/**
 * Inspired by:
 * https://github.com/0x006F/react-media-recorder
 * https://github.com/wmik/use-media-recorder
 */
const useMediaRecorder = ({
  stopStreamsOnStop = true,
  askPermissionOnMount = false,
  mediaRecorderOptions,
  onDataAvailable = (v) => v,
  onStart = () => {},
  onStop,
}: MediaRecorderHookProps) => {
  const mediaStream = useRef<MediaStream | null>(null);
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const mediaChunks = useRef<Blob[]>([]);
  const silentMode = useRef<boolean>(false);

  const [status, setStatus] = useState<StatusMessages>('idle');
  const [error, setError] = useState<keyof typeof RecorderErrors>('NONE');
  const [mediaBlobUrl, setMediaBlobUrl] = useState<string | undefined>(undefined);
  const [isAudioMuted, setIsAudioMuted] = useState<boolean>(false);

  const getMediaStream = useCallback(() => {
    // setStatus('acquiring_media');
    return getUserMedia({ audio: true })
      .then((stream) => (mediaStream.current = stream))
      .catch((e) => setError(e.name));
    // .finally(() => setStatus('idle'));
  }, []);

  // init
  useEffect(() => {
    const mediaStreamInstance = mediaStream.current;

    if (!window.MediaRecorder) {
      throw new ReferenceError(
        'MediaRecorder is not supported in this browser. Please ensure that you are running the latest version of chrome/firefox/edge.'
      );
    }

    // For future use
    // if (typeof audio === "object") {
    //   checkConstraints(audio);
    // }

    if (mediaRecorderOptions?.mimeType && !MediaRecorder.isTypeSupported(mediaRecorderOptions.mimeType)) {
      console.error(`The specified MIME type you supplied for MediaRecorder doesn't support this browser`);
    }

    if (!mediaStreamInstance && askPermissionOnMount) {
      getMediaStream();
    }

    return () => {
      if (mediaStreamInstance) {
        const tracks = mediaStreamInstance.getTracks();
        tracks.forEach((track) => track.clone().stop());
      }
    };
  }, [mediaRecorderOptions, askPermissionOnMount, getMediaStream]);

  // Media Recorder Lifecycle handlers

  const recorderLifecycleHooks = useMemo<MediaRecorderLifecycleHooks>(
    () => ({
      ondataavailable: (event) => {
        if (event.data.size) {
          mediaChunks.current.push(event.data);
          onDataAvailable(event.data);
        }
      },
      onstart: onStart,
      onstop: () => {
        const [chunk] = mediaChunks.current ?? [];
        const [url, blob] = createURLfromChunks(mediaChunks.current, { type: chunk?.type ?? 'audio/wav' }); //! Seems like Safari doesn't support ".wav". So we have to save it in the original type
        setMediaBlobUrl(url);
        if (!silentMode.current) {
          setStatus('stopped');
        }
        onStop?.(url, blob, structuredClone(mediaChunks.current));
      },
      onpause: () => {},
      onresume: () => {},
      onerror: () => {
        setError('NO_RECORDER');
        setStatus('idle');
      },
    }),
    [onDataAvailable, onStart, onStop]
  );

  // Media Recorder Action Handlers

  const start = useCallback(
    async (timeslice?: number) => {
      setError('NONE');

      // check permissions first
      if (!mediaStream.current) {
        await getMediaStream();
      }

      const isStreamEnded = mediaStream.current?.getTracks().some((track) => track.readyState === 'ended');
      // Getting a new stream again
      if (isStreamEnded) {
        await getMediaStream();
      }

      // do nothing if a user still has no permissions (getMediaStream returned an error)
      if (!mediaStream.current?.active) return;

      silentMode.current = false;

      mediaRecorder.current = new MediaRecorder(mediaStream.current);

      mediaRecorder.current.ondataavailable = recorderLifecycleHooks.ondataavailable;
      mediaRecorder.current.onstop = recorderLifecycleHooks.onstop;
      mediaRecorder.current.onstart = recorderLifecycleHooks.onstart;
      mediaRecorder.current.onpause = recorderLifecycleHooks.onpause;
      mediaRecorder.current.onresume = recorderLifecycleHooks.onresume;
      mediaRecorder.current.onerror = recorderLifecycleHooks.onerror;

      mediaRecorder.current.start(timeslice);
      setStatus('recording');
    },
    [
      getMediaStream,
      recorderLifecycleHooks.ondataavailable,
      recorderLifecycleHooks.onerror,
      recorderLifecycleHooks.onpause,
      recorderLifecycleHooks.onresume,
      recorderLifecycleHooks.onstart,
      recorderLifecycleHooks.onstop,
    ]
  );

  const pause = useCallback(() => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      mediaRecorder.current.pause();
      setStatus('paused');
    }
  }, []);

  const resume = useCallback(() => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'paused') {
      mediaRecorder.current.resume();
      setStatus('recording');
    }
  }, []);

  const stop = useCallback(
    (silently = false) => {
      silentMode.current = silently;
      if (!mediaRecorder.current) return;
      if (mediaRecorder.current.state !== 'inactive') {
        mediaRecorder.current.stop();
        if (stopStreamsOnStop && mediaStream.current) {
          mediaStream.current.getTracks().forEach((track) => track.stop());
        }
        mediaChunks.current = [];
        if (!silentMode.current) {
          setStatus('stopping');
        }
      }
    },
    [stopStreamsOnStop]
  );

  // Media Recorder Helper Handlers

  const muteAudioFactory = useCallback(
    (mute: boolean) => () => {
      setIsAudioMuted(mute);
      if (mediaStream.current) {
        mediaStream.current.getAudioTracks().forEach((audioTrack) => (audioTrack.enabled = !mute));
      }
    },
    []
  );

  const clearBlobUrl = useCallback(() => {
    if (mediaBlobUrl) {
      URL.revokeObjectURL(mediaBlobUrl);
    }
    setMediaBlobUrl(undefined);
    // setStatus('idle');
  }, [mediaBlobUrl]);

  return {
    state: {
      error: RecorderErrors[error],
      status,
      mediaBlobUrl,
      previewAudioStream: mediaStream.current ? new MediaStream(mediaStream.current.getAudioTracks()) : null,
      isAudioMuted,
    },
    start,
    pause,
    resume,
    stop,
    muteAudio: muteAudioFactory(true),
    unMuteAudio: muteAudioFactory(false),
    clearBlobUrl,
  };
};

export default useMediaRecorder;
