import { default as RcTooltip } from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import { TooltipProps as RcTooltipProps } from 'rc-tooltip/lib/Tooltip';

import './styles.css';

export type TooltipProps = RcTooltipProps;

const Tooltip = (props: TooltipProps) => (
  <RcTooltip placement="top" trigger={['click']} destroyTooltipOnHide {...props} />
);

export default Tooltip;
