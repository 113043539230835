import { create } from 'zustand';

import './useHandleRecording';

export type SentenceAudioState = {
  id: number;
  blob: Blob;
  blobUrl: string;
  chunks: Blob[];
};

type SentenceRecordingState = {
  recordings: Record<number, SentenceAudioState | null>;
  setRecordingById: (id: number, value: SentenceAudioState | null) => void;
  reset: () => void;
};

const useRecordingStore = create<SentenceRecordingState>()((set) => ({
  recordings: {},
  setRecordingById: (id, value) => set((prevState) => ({ recordings: { ...prevState.recordings, [id]: value } })),
  reset: () => set({ recordings: {} }),
}));

export default useRecordingStore;
