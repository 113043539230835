import styled from 'styled-components';

import CardComponent from 'components/ui/Card';

export const ErrorCard = styled(CardComponent)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 36px;
  margin: 0 auto;
  padding: 48px 36px;
  max-width: 1200px;
`;
