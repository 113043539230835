import ContentCommon from 'components/ui/ContentCommon';
import type { ContentCommonProps } from 'components/ui/ContentCommon';

import * as Styled from './styles';

export type ContentCenteredProps = ContentCommonProps;

const ContentCentered = ({ children, ...restProps }: ContentCenteredProps) => (
  <ContentCommon {...restProps}>
    <Styled.ContentCentered>{children}</Styled.ContentCentered>
  </ContentCommon>
);

export default ContentCentered;
