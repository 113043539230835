import styled from 'styled-components';

import CardComponent from 'components/ui/Card';

export const Card = styled(CardComponent)`
  cursor: pointer;

  &.active {
    pointer-events: none;
  }
`;
