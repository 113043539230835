import Lottie from 'lottie-react';

import loader from 'assets/loader.json';

export const Loader = ({ style = {} }: { style?: React.CSSProperties }) => (
  <Lottie
    style={{
      width: 254,
      height: 254,
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      ...style,
    }}
    animationData={loader}
    loop={true}
  />
);

export default Loader;
