import type { InputHTMLAttributes } from 'react';

import * as Styled from './styles';

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  /**
   * Renders an error text under the input
   */
  error?: string;
  /**
   * Set input in error state
   */
  invalid?: boolean;
  className?: string;
};

/**
 * Primary UI component for entering text
 */
export const Input = ({ children, invalid = false, error, className, ...inputProps }: InputProps) => {
  return (
    <Styled.InputBase className={className}>
      <Styled.InputRoot aria-invalid={invalid && !inputProps.disabled ? 'true' : 'false'} {...inputProps} />
      {/* Optional UI/UX improvement */}
      {invalid && error && !inputProps.disabled && <Styled.InputAlert role="alert">{error}</Styled.InputAlert>}
    </Styled.InputBase>
  );
};

export default Input;
