import styled from 'styled-components';

import CardComponent from 'components/ui/Card';

export const Card = styled(CardComponent)`
  display: grid;
  grid-gap: 82px;
  padding: 60px;
  word-spacing: 5px;

  .LessonResult__Word {
    display: inline-flex;

    &.isSelected,
    &.hasSound {
      position: relative;

      &:before {
        --dif: 10%;

        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: calc(var(--dif) / -2);
        width: calc(100% + var(--dif));
        height: 100%;
        background-color: var(--indigo-100);
        border-radius: 26px;
        opacity: 0.4;
        transition: opacity 0.2s;
      }
    }

    &.hasSound {
      &:before {
        background-color: var(--purple-a300);
        opacity: 1;
      }
    }
  }
`;

export const Labels = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px 60px;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
