import { cloneElement } from 'react';
import * as Styled from './styles';

export type FormControlType = 'radio' | 'checkbox';

export type FormControlProps = React.InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  id?: string;
  /**
   * Types of form group. Should accept only "radio" or "checkbox"
   */
  type?: FormControlType;
  /**
   * Control to render. Should accept only label element
   */
  children: React.ReactElement<React.LabelHTMLAttributes<HTMLLabelElement>, 'label'>;
};

export const FormControl = ({ id, name, type = 'radio', children, ...inputProps }: FormControlProps) => {
  const identifier = id || name;

  return (
    <Styled.FormControlRoot>
      <input readOnly type={type} name={name} id={identifier} {...inputProps} />
      {typeof children === 'string' ? (
        <label htmlFor={identifier}>{children}</label>
      ) : (
        cloneElement(children, { htmlFor: identifier })
      )}
    </Styled.FormControlRoot>
  );
};

export default FormControl;
