import { ReactNode } from 'react';

import Button from 'components/ui/Button';
import ContentCommon from 'components/ui/ContentCommon';
import type { ContentCommonProps } from 'components/ui/ContentCommon';

import { ARROW_LEFT_ICON, ARROW_RIGHT_ICON } from 'assets/icons';

import * as Styled from './styles';

type ContentLessonProps = ContentCommonProps & {
  instruction?: ReactNode;
  onBack?: () => void | undefined;
  onForward?: () => void | undefined;
  onFinish?: () => void | undefined;
  actions?: ReactNode;
  children: string | ReactNode;
};

const ContentLesson = ({ instruction, onBack, onForward, onFinish, actions, children }: ContentLessonProps) => (
  <ContentCommon gradientCirclePosition="center">
    <Styled.Wrapper>
      {instruction}
      <Styled.Content>{children}</Styled.Content>
      {actions ||
        ((onBack || onForward || onFinish) && (
          <Styled.Actions>
            {onBack && (
              <Button onClick={onBack} iconLeft={ARROW_LEFT_ICON}>
                Back
              </Button>
            )}
            {onForward && (
              <Button onClick={onForward} iconRight={ARROW_RIGHT_ICON}>
                Next
              </Button>
            )}
          </Styled.Actions>
        ))}
    </Styled.Wrapper>
  </ContentCommon>
);

export default ContentLesson;
