import styled, { css } from 'styled-components';

import type { MenuCardProps } from './MenuCard';

const pillBgColorByStatus: Record<NonNullable<MenuCardProps['status']>, string> = {
  ready: 'inherit',
  locked: 'var(--purple-a400)',
  completed: 'var(--indigo-a200)',
};

export const MenuCard = styled.div<Pick<MenuCardProps, 'status'>>`
  display: grid;
  gap: 32px;
  position: relative;
  width: 480px;
  border-radius: 28px;
  cursor: pointer;

  ${({ status = 'ready' }) =>
    ['locked', 'completed'].includes(status)
      ? css`
          cursor: not-allowed;
          user-select: none;

          .MenuCard__Card:after {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            /* TODO: replace to var */
            background-color: #1d2165;
            opacity: 0.4;
            border-radius: 28px;
          }
          .MenuCard__Pill {
            background-color: ${pillBgColorByStatus[status]};
          }

          .MenuCard__Text {
            color: var(--white-transparent-10);
          }
        `
      : css`
          .MenuCard__Card {
            transition: background-color 0.2s;
          }

          &:hover {
            .MenuCard__Card {
              background-color: var(--navy-900);
            }
          }
        `}

  .MenuCard__Card {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 300px;
    position: relative;
    overflow: hidden;
  }

  .MenuCard__Pill {
    position: absolute;
    top: 0;
    left: -32px;
    z-index: 1;
    padding: 16px 32px 16px 48px;
  }

  .MenuCard__Text {
    text-transform: capitalize;
  }
`;
