import { useUpdateEffect } from 'react-use';

import useHandlePlayback from 'hooks/useHandlePlayback';
import useMediaRecorder, { RecorderErrors } from 'hooks/useMediaRecorder';

import useRecordingStore from './useRecordingStore';

const CONTINUOUS_RECORDING = true; // Feature-flag that controls auto-start of recording when we go to the next sentence

export const useHandleRecording = (sentenceId: number, step: number = 0) => {
  const { setRecordingById, recordings } = useRecordingStore();
  const { playbackStatus, actions, resetStatus, audioProps: playbackAudioProps } = useHandlePlayback();

  const mediaRecorder = useMediaRecorder({
    // askPermissionOnMount: true,
    onStop: (blobUrl, blob, chunks) => setRecordingById(sentenceId, { blobUrl, blob, id: sentenceId, chunks }),
  });

  const resetRecordingStatus = () => {
    if (mediaRecorder.state.status === 'recording') {
      mediaRecorder.stop(true);
    }
    actions?.pause();
    mediaRecorder.clearBlobUrl();
    setTimeout(() => {
      resetStatus();
    }, 100);
  };

  const resetRecordingState = () => {
    setRecordingById(sentenceId, null);
  };

  const restartRecording = () => {
    resetRecordingStatus();
    mediaRecorder.start();
  };

  const onRecordControlClick = async () => {
    if (
      mediaRecorder.state.status === 'idle' &&
      (mediaRecorder.state.error === RecorderErrors.NotAllowedError ||
        mediaRecorder.state.error === RecorderErrors.NOT_SUPPORTED)
    ) {
      alert(
        'Luca.ai requires access to your microphone. Go to your browser settings and grant the microphone permission to the webpage'
      );
    }
    if (mediaRecorder.state.status === 'idle') mediaRecorder.start();
    if (mediaRecorder.state.status === 'recording') mediaRecorder.stop();
    if (mediaRecorder.state.status === 'stopped') await actions?.toggle();
  };

  const controlStatus = typeof playbackStatus === 'undefined' ? mediaRecorder.state.status : playbackStatus;

  const isAudioAvailable = !!recordings?.[sentenceId]?.blobUrl;
  const isActionsVisible =
    (step === 0 && mediaRecorder.state.status === 'recording') || Object.keys(recordings ?? {}).length > 0;
  const isRecordAgainDisabled = mediaRecorder.state.status === 'recording' || !recordings?.[sentenceId]?.blobUrl;

  useUpdateEffect(() => {
    if (!!sentenceId && CONTINUOUS_RECORDING) {
      setTimeout(() => {
        mediaRecorder.start(); //! We have to wait until recording is properly stopped. That's why we should call it on next tick of EventLoop
      }, 100);
    }
  }, [sentenceId]);

  return {
    controlStatus,
    isAudioAvailable,
    isActionsVisible,
    isRecordAgainDisabled,
    audioProps: {
      ...playbackAudioProps,
      src: recordings?.[sentenceId]?.blobUrl,
    },
    resetRecordingState,
    resetRecordingStatus,
    restartRecording,
    onRecordControlClick,
  };
};
